import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exceptionnals',
  templateUrl: './exceptionnals.component.html',
  styleUrls: ['./exceptionnals.component.scss']
})
export class ExceptionnalsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
